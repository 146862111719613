@keyframes moveleft {
  0% {
    transform: translate(0vw);
  }
  32% {
    transform: translate(0vw);
  }
  34% {
    transform: translate(-100vw);
  }
  65% {
    transform: translate(-100vw);
  }
  67% {
    transform: translate(-200vw);
  }
  98% {
    transform: translate(-200vw);
  }
  100% {
    transform: translate(-300vw);
  }
}

.slider {
  background-color: #f4f4f4;
  justify-content: left !important;
  align-items: left !important;
  width: 100vw;
  overflow: hidden;
}

.wrapper {
  width: 133vw;
  display: flex;
  align-items: left !important;
  justify-content: left !important;
}
.slides {
  display: flex;
  margin: auto;
  width: 320px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.slider-icon {
  height: 25px;
  margin: 16px;
}

@media screen and (max-width: 960px) {
  .slides {
    animation-name: moveleft;
    animation-duration: 13s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .wrapper {
    width: 400vw;
  }
}
