:root {
  --car-height-primary: 16px;
  --car-width-primary: calc(var(--car-height-primary) * 1.49);
  --car-width-margin: 10px;
}

.workwithfrulla_container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  justify-items: center;
}

.workwithfrulla_list_car {
  list-style: none;
  padding-left: 0;
  margin: 0 24px;
}

.workwithfrulla_item {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 35px;
}

.workwithfrulla_p {
  margin-bottom: 16px;
  font-size: 16px;
}

.extra_margin_bottom {
  margin: 0 24px 80px 24px;
}

.workwithfrulla_item::before {
  content: " ";
  display: inline-block;
  height: var(--car-height-primary);
  width: var(--car-width-primary);
  background-image: url(../../assets/logo/Frulla_logo-bil.png);
  background-size: cover;
  margin-right: var(--car-width-margin);
}

@media screen and (min-width: 700px) {
  .workwithfrulla_p {
    margin-left: calc(var(--car-width-primary) + var(--car-width-margin));
  }
}
