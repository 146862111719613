.successform_item {
  display: block;
  text-align: center;
}

.successform_heading {
  font-size: 30px;
  margin-bottom: 15px;
}

.successform_p {
  font-size: 22px;
  margin-bottom: 15px;
}
