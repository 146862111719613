.error-order-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 500px;
  align-self: center;
  margin-bottom: 0px;
  margin-top: 20px;
}

.error-order-p {
  align-items: center;
  justify-content: center;
  width: 360px;
  margin-bottom: 20px;
  font-size: 16px;
}

.error-order-text {
  color: rgba(255, 0, 0);
  font-size: 18px;
}

.error-order-buttons-container {
  display: flex;
  flex-direction: column;
}

.error-order-button {
  border-radius: 8px;
  width: 300px;
  height: 51px;
  background-color: #883210;
  color: white;
  font-size: 18px;
  border: none;
  outline: none;
  align-self: center;
  margin: 20px 0;
}

.error-order-cancel-button {
  background-color: gray;
  margin-top: 16px;
}
