.FooterSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.FooterLink {
  margin-left: 24px;
  margin-top: 28px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  text-decoration: none;
}

.FooterSeperator {
  width: 90%;
  height: 1px;
  background-color: black;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 50px;
}

.FooterLogo {
  margin-top: -25px;
}

.FirstFooterContainer {
  margin-top: 75px;
}

.LastFooterContainer {
  padding-bottom: 100px;
}

@media screen and (min-width: 700px) {
  .FooterContainer {
    min-height: 80px;
    padding-bottom: 100px;
    margin-top: 75px;
  }

  .FooterSection {
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: 10%;
  }
}
