.HeaderContainer {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  width: 100%;
  height: 85px;
  background-color: white;
}

.ShoppingCart {
  position: relative;
  margin: 0px;
  margin-right: 25px;
  border: none;
  outline: none;
  background-color: transparent;
}

.Logo {
  height: 25px;
  margin-left: 25px;
}

.ProductCountIcon {
  position: absolute;
  top: -10px;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: red;
  color: white;
  font-size: 12px;
}
