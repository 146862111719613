/* Collapsible */

.background_infopage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_infopage {
  max-width: 1050px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 24px;
}

.section_header {
  height: 90px;
  background-color: #f4f4f4;
}

.text_infopage {
  margin-bottom: 1rem;
}

.contact_infopage {
  margin-top: 0px;
  text-align: center;
}

.button_infopage {
  text-align: center;
}

.heading_infopage,
.text_infopage,
.contact_infopage,
.heading_inText {
  font-size: 16px;
  margin-left: 4px;
}

.h2_infopage {
  margin-left: 4px;
  margin-top: 0;
}

/* Email form */

.sendFormButton {
  width: 330px;
  height: 48px;
  border-radius: 30px;
  border: 0px;
  background-color: #f6a237;
  box-shadow: 0px 1.5px rgba(0, 0, 0, 0.3);
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  margin: 16px auto 8px auto;
  letter-spacing: 0.5px;
}

.sendFormButton:hover {
  background-color: rgb(252, 190, 14);
  box-shadow: 0px 1.5px 4px 2px rgba(0, 0, 0, 0.2);
}

.sendFormButton:disabled {
  background-color: rgb(147, 147, 147);
}

.sendFormButton {
  transition: background-color 0.3s, box-shadow 0.3s;
}

.emailForm {
  width: 350px;
}

@media screen and (min-width: 700px) {
  .emailForm {
    width: 600px;
  }
}

@media screen and (max-width: 350px) {
  .sendFormButton {
    width: 250px;
  }
  .emailForm {
    width: 250px;
  }
}
