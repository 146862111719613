@font-face {
  font-family: 'Avenir-Next';
  src: local('Avenir-Next'),
    url(./fonts/Avenir-Next/AvenirNextLTPro-Regular.otf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir-Next';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
