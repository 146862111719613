.SliderConteriner {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 50px;
}

.CategoryTitle {
  margin: 50px 16px 0px 16px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.CategoryProducts {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
}
