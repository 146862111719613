.TermsContainer {
  max-width: 800px;
  width: 100%;
  padding-bottom: 50px;
}

.MarginLeft {
  margin-left: 24px;
  margin-bottom: 40px;
  margin-top: 30px;
}

.TermsTitle {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
  font-size: 40px;
}

.TermsText {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}

.ListText {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 24px;
}
