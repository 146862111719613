p {
  margin: 0px;
  font-family: "Avenir-Next", serif;
}

h1 {
  font-family: "Avenir-Next", serif;
}

h2 {
  font-family: "Avenir-Next", serif;
}

h3 {
  font-family: "Avenir-Next", serif;
}
h4 {
  font-family: "Avenir-Next", serif;
}

li {
  font-family: "Avenir-Next", serif;
}

a:visited {
  color: black;
}
a {
  text-decoration: none;
}

.CategorySectionTitle {
  text-align: center;
  font-size: 28px;
  margin-top: 8px;
}

.submitButton {
  width: 330px;
  height: 48px;
  border-radius: 30px !important;
  color: white !important;
  margin-top: 8px;
  align-self: center;
}

.errorButton {
  width: 330px;
  height: 48px;
  border-radius: 30px !important;
  align-self: center;
  margin-top: 16px !important;
}

.errorButton_small {
  width: 100px;
  height: 39px;
  border-radius: 24px !important;
  align-self: center;
  font-size: 10px !important;
}

@media screen and (max-width: 350px) {
  .submitButton {
    width: 250px;
  }
  .errorButton {
    width: 250px;
  }
}
