/* Collapsible arrow*/
.collapsible_open > div > .arrow_container {
  background: #f4a240;
  color: white;
  transform: rotate(-180deg);
  transition-duration: 0.3s;
}

.collapsible_closed > div > .arrow_container {
  background: white;
  color: rgba(0, 0, 0, 0.5);
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.arrow_container {
  width: 32px;
  height: 32px;
  border: 0px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Collapsible container*/
.collapsible_container {
  background: white;
  border: 0px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
}

/* Collapsible header*/
.collapsible_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
}

.collapsible_header_left {
  display: flex;
}

.collapsible_h2 {
  margin: 0;
  font-size: 16px;
}

.collapsible_car {
  height: 18.9px;
  width: 27px;
  margin-right: 15px;
}

.collapsible_open > .collapsible_header {
  color: #f4a240;
  padding: 20px 15px 10px 15px;
  transition-duration: 0.2s;
}

.collapsible_closed > .collapsible_header {
  color: rgba(0, 0, 0, 0.85);
  padding: 10px 15px 10px 15px;
  transition-duration: 0.2s;
}

/* Collapsible body*/
.collapsible_body {
  background: white;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}

.collapsible_open > .collapsible_body {
  max-height: 150vh;
  padding-bottom: 20px;
  transition-duration: 0.7s;
}

.collapsible_closed > .collapsible_body {
  max-height: 0vh;
  overflow: hidden;
  transition-duration: 0.3s;
}

.collapsible_body > p {
  margin-bottom: 14px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.767);
}

.collapsible_body > h2,
h3,
h4 {
  margin: 6px 0;
  font-size: 16px;
}

.collapsible_body > li {
  margin: 2px 0;
  font-size: 16px;
  list-style-type: none;
}

.collapsible_body > span {
  font-size: 12px;
  font-style: italic;
  font-weight: 100;
}
