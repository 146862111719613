.addTeam_container {
  margin: 10px;
  max-width: 500px;
  display: inline-block;
}

.teams_container {
  margin: 10px;
}

.search_and_addteam {
  display: flex;
  justify-content: left;
}

.searchbox_container {
  min-width: 300px;
  margin-right: 25px;
}

.greybutton {
  background-color: grey;
}

.bluebutton {
  background-color: blue;
}

.margin_top {
  margin-top: 50px;
}

.underline {
  text-decoration: underline;
}
