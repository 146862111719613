.ProductInfoContainer {
  display: block;
  width: 300px;
  margin: 10px auto;
  padding-bottom: 35px;
}

.BackArrowImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 3px solid black;
  margin: 15px 0 0 15px;
}

.BackArrowImage {
  height: 30px;
  width: 30px;
}

.ProductTitle {
  font-weight: bold;
  font-size: 32px;
  color: black;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.ProductImage {
  border-radius: 8px;
  width: 300px;
  margin: 10px auto;
}

.ProductInfoTitle {
  font-weight: bold;
  line-height: 26px;
  font-size: 16px;
  color: black;
  margin-top: 24px;
}

.ProductInfoNutritionContainer {
}

.ContentDeclarationContainer {
}

.ProductInfoTitleText {
  line-height: 26px;
  font-size: 16px;
  color: black;
  margin-top: 8px;
}

.ProductInfoTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 750px) {
  .ProductInfoContainer {
    width: 700px;
    display: grid;
    grid-template-columns: 300px 300px;
    column-gap: 35px;
    row-gap: 20px;
    grid-template-areas:
      "header header"
      "image info"
      "nutrition productcontent";
  }

  .ProductTitle {
    grid-area: header;
  }

  .ProductImage {
    grid-area: image;
  }

  .ProductMainInfo {
    grid-area: info;
  }

  .ProductInfoNutritionContainer {
    grid-area: nutrition;
  }

  .ContentDeclaration {
    grid-area: productcontent;
  }
}
