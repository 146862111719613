.ContactContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
}

.ContactBackArrowButton {
  position: relative;
  left: -45%;
}

.ContactTitle {
  font-size: 40px;
  margin-bottom: 24px;
}

.ContactList {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
}

.ContactListItem {
  margin-bottom: 8px;
  align-self: center;
}
